body {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin: 20px;
}

ol, ul {
  padding-left: 30px;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px;
  display: flex;
  align-items:flex-end;
  justify-content: center;
}

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.game {
  display: flex;
  flex-direction: row;
}

.game-info {
  margin-left: 20px;
}

svg {
  height: 100%;
  width: 100%;
}

.size-1 {
  height: 50%;
  width: 50%;
}

.size-2 {
  height: 75%;
  width: 75%;
}

.size-3 {
  height: 100%;
  width: 100%;
}

.move-option-row {
  display: flex;
}

.selected {
  background-color: lightblue;
}